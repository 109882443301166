import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

const ContactForm = () => {
  return (
    <form action="//formspree.io/ekoshopabc@gmail.com" method="POST">
      <label>
        <FormattedMessage id="kontakt_your_name" />
        <a
          href="#!"
          rel="noopener noreferrer"
          style={{ color: "red", border: "0" }}
        >
          *
        </a>
        <input
          placeholder="..."
          className="input primary"
          type="text"
          name="name"
          style={{
            minWidth: 265,
            //textAlign: 'center',
            justifyContent: "center"
          }}
        />
      </label>
      <label>
        <FormattedMessage id="kontakt_email" />
        <a
          href="#!"
          rel="noopener noreferrer"
          style={{ color: "red", border: "0" }}
        >
          *
        </a>
        <input
          placeholder="E-mail..."
          className="input primary"
          type="email"
          name="email"
          style={{
            minWidth: 265,
            //textAlign: 'center',
            justifyContent: "center"
          }}
        />
      </label>
      <label>
        <FormattedMessage id="kontakt_subject" />
        <input
          placeholder="Abc.."
          className="input primary"
          type="text"
          name="subject"
          style={{
            minWidth: 265,
            //textAlign: 'center',
            justifyContent: "center"
          }}
        />
      </label>
      <label>
        <FormattedMessage id="kontakt_message" />
        <textarea
          placeholder="Abc..."
          className="input primary"
          type="text"
          name="message"
          style={{
            minWidth: 265,
            //textAlign: 'center',
            justifyContent: "center"
          }}
        />
        <a
          href="#!"
          rel="noopener noreferrer"
          style={{ fontSize: "10px", color: "red", border: "0" }}
        >
          <FormattedMessage id="obavezno_polje" /> *<br />
        </a>
      </label>

      <button type="submit">
        <FormattedMessage id="kontakt_send" />
      </button>
    </form>
  );
};

export default ContactForm;
