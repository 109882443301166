import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import OurFirmInfo from "../components/OurFirmInfo";

const IndexPage = () => {
  return (
    <Layout fullMenu>
      <article id="main">
        <section className="wrapper style5">
          <h2 style={{ textAlign: "center" }}>
            <FormattedMessage id="kontakt_strana_naslov" />
          </h2>
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-evenly"
            }}
          >
            <div className="kontakt-wrapper">
              <section
                className="special wrapper style5 kontakt-sekcija"
                style={{
                  color: "red",
                  textAlign: "left"
                }}
              >
                <h2 style={{ textAlign: "center" }}>
                  <FormattedMessage id="kontaktiraj_nas" />
                </h2>
                <ContactForm />
              </section>
              <OurFirmInfo />
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default IndexPage;
