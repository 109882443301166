import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import phone_img from "../assets/images/phone.webp";
import company_img from "../assets/images/company.webp";
import email_img from "../assets/images/email.webp";
import location_img from "../assets/images/location.webp";

const OurFirmInfo = () => {
  return (
    <section
      className="special wrapper style5"
      style={{
        color: "red",
        textAlign: "left"
      }}
    >
      <h2 style={{ color: "red", textAlign: "center" }}>
        <FormattedMessage id="kontakt_about_firm" />
      </h2>

      <ul>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row"
          }}
        >
          <img src={phone_img} alt="Vatra" height="50" width="50" />
          <p style={{ marginLeft: "10px", fontSize: "14px" }}>
            +421 951 200 263
          </p>
        </div>
      </ul>
      <ul>
        <div
          className="image"
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
            alignContent: "center"
          }}
        >
          <img src={email_img} alt="Vatra" height="50" width="50" />
          <p style={{ marginLeft: "10px", fontSize: "14px" }}>
            ekoshopabc@gmail.com
          </p>
        </div>
      </ul>
      <ul>
        <div
          className="image"
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
            alignContent: "center"
          }}
        >
          <img src={location_img} alt="Vatra" height="50" width="50" />
          <p style={{ marginLeft: "10px", fontSize: "14px" }}>
            ABC TRADE BUSINESS s.r.o. Lietavska 2
          </p>
        </div>
      </ul>
      <ul>
        <div
          className="image"
          style={{
            flexDirection: "row",
            flex: 1,
            display: "flex",
            alignContent: "center"
          }}
        >
          <img src={company_img} alt="Vatra" height="50" width="50" />
          <p style={{ marginLeft: "10px", fontSize: "14px" }}>
            <FormattedMessage id="kontakt_pib" /> : 51064570
            <br />
          </p>
        </div>
      </ul>
    </section>
  );
};

export default OurFirmInfo;
